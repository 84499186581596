import { template as template_1a36e30e3a274323ba2af361dae0c92b } from "@ember/template-compiler";
const FKLabel = template_1a36e30e3a274323ba2af361dae0c92b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
