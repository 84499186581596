import { template as template_a2007b81b6314021a1eb7297b964c64b } from "@ember/template-compiler";
const FKControlMenuContainer = template_a2007b81b6314021a1eb7297b964c64b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
