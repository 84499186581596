import { template as template_c6b8be4884d24d118828cb837158b1cd } from "@ember/template-compiler";
const FKText = template_c6b8be4884d24d118828cb837158b1cd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
